import style from "./dashboard.module.scss";
import { useState, useEffect, useRef } from "react";
import DashboardContext from "context/Dashboard/DashboardContext";
import TopMenu from "../topmenu/TopMenu";
import SideBarContainer from "../navbar/NavBarContainer";
import React from "react";
import { useAppDispatch } from "hooks/redux";
import { useRefreshQuery } from "features/Auth/RefreshApiSlice";
import { login } from "features/Auth/AuthSlice";

import { Toaster } from "react-hot-toast";

// import { setFunding } from "features/Transactions/TransactionSlice";
import LoadingSpinner from "components/Spinner/Spinner";
import { useGetPaymentChannelsQuery } from "features/Wallet/WalletApiSlice";
import { setChannels, setPaymentOptions } from "features/Wallet/WalletSlice";
import { useGetPaymentOptionQuery } from "features/Wallet/WalletApiSlice";
import { useBeneficiaryQuery } from "features/Beneficiaries/BeneficiariesApiSlice";

import { setBeneficiary } from "features/Beneficiaries/BeneficiariesSlice";
import formatTimestamp from "utils/formatTimestamp";
import { useIdle, useCookie } from "react-use";
import { useLogoutMutation } from "features/Auth/LogoutApiSlice";
import { Box } from "@chakra-ui/react";

const OtherDashboard = ({ children }: { children: React.ReactNode }) => {
  const [currentPagename, setCurrentPagename] = useState<string>("Dashboard");
  const [currentId, setCurrentId] = useState<number>(0);
  // bgContentColor?: string,
  // bgTopMenuColor?: string,
  // bgSideMenuColor?: string,
  // setBgContentColor?: (color: string) => void,
  // setBgTopMenuColor?: (color: string) => void,
  // setBgSideMenuColor?: (color: string) => void,
  const [bgContentColor, setBgContentColor] = useState<string>("#FFF");
  const [bgTopMenuColor, setBgTopMenuColor] = useState<string>("#FFF");
  const [bgSideMenuColor, setBgSideMenuColor] =
    useState<string>("var(--gray-light)");
  //remember

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [remember, , deleteRemember] = useCookie("remember");
  const [jara, , deleteJaraAccess] = useCookie("jara_access");

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // view
  const scrollIntoView = () => {
    scrollContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  //winow
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const scrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if(currentId === 112){
      scrollDown();
    }

  }, [])



  useEffect(() => {
    scrollIntoView();
  }, [currentId]);

  useEffect(() => {
    scrollToTop();
  }, [currentPagename]);

  const dispatch = useAppDispatch();

  const { data, isLoading, isFetching } = useRefreshQuery(undefined, {
    skip: !jara,
  });

  useEffect(() => {
    dispatch(login(data));
  }, [data, dispatch]);

  const [logout] = useLogoutMutation();
  const isIdle = useIdle(600000);

  useEffect(() => {
    const logoutUser = async () => {
      await logout(undefined);
      deleteRemember();
      deleteJaraAccess();
      window.location.href = "/sign-in";
    };
    if (isIdle && !remember) {
      logoutUser();
    }
  }, [isIdle, remember]);

  // const transaction = useAppSelector(state => state.transaction)

  // const { data: paginatedTransactions, isLoading: loadingTransactions } = useGetTransactionsQuery({page: transaction.page});

  // const { data: paginatedFunding, isLoading : loadingFunding} = useGetFundingQuery({page: transaction.fundingPage} );
  const { data: paymentChannels, isLoading: loadingPaymentChannels } =
    useGetPaymentChannelsQuery(undefined);

  //  useEffect(()=> {
  //     dispatch(setTransactions(paginatedTransactions))
  //     dispatch(setFunding(paginatedFunding))
  //     dispatch(setChannels(paymentChannels))

  // } , [dispatch, paginatedTransactions , paginatedFunding , paymentChannels])

  // //Get Transaction History

  //GET PAYMENT ACTIONS
  const { data: paymentOptions, isLoading: loadingPaymentOptions } =
    useGetPaymentOptionQuery(undefined);

  //GET BENEFICIARIES
  const {
    data: beneficiary,
    isLoading: isLoadingBeneficiary,
    isSuccess: isSuccessBeneficiary,
  } = useBeneficiaryQuery(undefined);

  useEffect(() => {
    if (isSuccessBeneficiary) {
      let data_ = beneficiary?.data?.map((item: any) => {
        //number provider name , time
        let time = formatTimestamp(item.created_at);
        let number = item.beneficiary_number;
        const service = item?.service ? JSON.parse(item.service) : {};
        const content = service?.content?.transactions;
        const name = service?.customerName
          ? service?.customerName
          : content?.type;
        const provider = content?.product_name;
        const category = item?.category;

        return { time, number, name, provider, category };
      });
      dispatch(setBeneficiary(data_));
    }
  }, [beneficiary?.data, dispatch, isSuccessBeneficiary]);

  useEffect(() => {
    dispatch(setChannels(paymentChannels));
    dispatch(setPaymentOptions(paymentOptions));
  }, [dispatch, paymentChannels, paymentOptions]);
  // //Get Paginated Transactions

  return (
    <DashboardContext.Provider
      value={{
        setCurrentPagename,
        setBgTopMenuColor,
        setBgContentColor,
        setBgSideMenuColor,
        currentPagename,
        currentId,
        setCurrentId,
      }}
    >
      <Toaster
        toastOptions={{
          duration: 4000,
          style: {
            fontSize: "16px",
          },
        }}
      />
      {isLoadingBeneficiary ||
      loadingPaymentOptions ||
      loadingPaymentChannels ||
      isLoading ||
      isFetching ? (
        <LoadingSpinner />
      ) : null}
      <main className={style.container}>
        <section
          className={`${style.sidebar} bg-[${bgSideMenuColor}] `}
          style={{
            background: bgSideMenuColor,
          }}
        >
          <SideBarContainer />
        </section>
        <section
          id="scrollable"
          className={`${style.content} ${style.other__content} bg-[${bgContentColor}] `}
        >
          <Box
            className={style.topmenu__container}
            style={{
              backgroundColor: bgTopMenuColor,
            }}
          >
            <TopMenu />
          </Box>

          <section
            ref={scrollContainerRef}
            className={`${style.content__bottom} bg-[${bgContentColor}] min-h-[100vh]`}
          >
            {children}
          </section>
        </section>
      </main>
    </DashboardContext.Provider>
  );
};

export default OtherDashboard;
