import React, { useState, useRef, useContext, useEffect } from "react";
import style from "./shop.module.scss";

import { Link } from "react-router-dom";
import CurrencyConverter from "utils/CurrencyConverter";
import { Grid, Text, Flex, Button, useDisclosure } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { toast } from "react-hot-toast";
import {
  addProductToCart,
  clearCart,
} from "features/Services/GiftCardService/GiftCardServiceSlice";
import ShortList from "../Card/ShortList";
import { setAmountToFund } from "features/Wallet/WalletSlice";
import ModalContainer from "components/Modals/ModalContainer";
import ProceedDialog from "./ProceedDialog";
import DashboardContext from "context/Dashboard/DashboardContext";

//import { useGetGiftCardProductQuery } from "features/Services/GiftCardService/GiftCardApiSlice";

interface Props {
  data: any;
  handleClick: (value: any) => void;
  handleNext: (value: any | undefined) => void;
  handleBack?: () => void;
}

const GiftCardShop: React.FC<Props> = ({
  data,
  handleClick,
  handleNext,
  handleBack,
}) => {
  const { products } = useAppSelector((state) => state.giftCardService);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setCurrentId } = useContext(DashboardContext);

  //   const { data: productData } = useGetGiftCardProductQuery(data.id, {
  //     skip: !data.id,
  //   });

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setCurrentId) {
      setCurrentId(data?.id ? data?.id : 1);
    }
  }, [data?.id]);

  const [quantity, setQuantity] = useState<number>(1);
  const handleAddQuantity = () => {
    setQuantity((prev) => prev + 1);
  };
  const handleRemoveQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const paragraphs: string[] = data?.description.split("\r\n") as any;

  const otherInfo = [
    "Delivery to Email",
    " Delivery to third party email",
    "Delivery to third party phone",
  ];

  const indexOfDataIdInProducts = products?.findIndex(
    (item: any) => item.id === data.id
  );
  let slicedProduct = [...products]?.slice(
    indexOfDataIdInProducts + 1,
    indexOfDataIdInProducts + 4
  );

  const threeProducts = [...products]?.slice(0, 3);


 
  const dispatch = useAppDispatch();

  const handleAddToCart = (e: any) => {
    e.stopPropagation();
    if (data?.available_quantity === 0) {
      return;
    } else {
      e.stopPropagation();

      dispatch(addProductToCart({ ...data, quantity: quantity }));

      onOpen();
    }
    // dispatch(addProductToCart({ ...data, quantity: quantity }));
    // toast.success("Product added to cart");
    // handleNext();
  };

  const handleProceed = (e: any) => {
    dispatch(clearCart());
    e.stopPropagation();

    if (data?.available_quantity === 0) {
      return;
    } else {
      const acc = data?.price * quantity;

      dispatch(setAmountToFund(CurrencyConverter(acc)));
      dispatch(addProductToCart({ ...data, quantity: quantity }));
      handleNext("3");
    }
  };

  return (
    <main className={style.shop} ref={scrollContainerRef}>
      <ModalContainer size={"md"} isOpen={isOpen} onClose={onClose}>
        <ProceedDialog
          handleProceed={() => handleNext("2")}
          handleContinueShopping={handleBack}
        />
      </ModalContainer>
      <section className={style.shop__top}>
        <div className={style.shop__top__left}>
          <div className={style.shop__top__left__image_container}>
            <img src={data.image_path} alt={data.name} />
          </div>
          <div className="hidden md:block">
            <button className={style.top_btn} type="button">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 11.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H12"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.48 2.81945L19.76 3.38945C19.9 3.66945 20.25 3.92945 20.56 3.98945L20.94 4.04945C22.08 4.23945 22.35 5.07945 21.53 5.90945L21.18 6.25944C20.95 6.49944 20.82 6.95944 20.89 7.27944L20.94 7.48945C21.25 8.86945 20.52 9.39944 19.32 8.67944L19.06 8.52944C18.75 8.34944 18.25 8.34944 17.94 8.52944L17.68 8.67944C16.47 9.40944 15.74 8.86945 16.06 7.48945L16.1099 7.27944C16.1799 6.95944 16.05 6.49944 15.82 6.25944L15.47 5.90945C14.65 5.07945 14.92 4.23945 16.06 4.04945L16.44 3.98945C16.74 3.93945 17.1 3.66945 17.24 3.38945L17.52 2.81945C18.06 1.72945 18.94 1.72945 19.48 2.81945Z"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className={style.top_btn__text}>
                Instant Code Delivery Via Mail
              </span>
            </button>
          </div>
          {/* <div className = "block md:hidden">
                    <Steps information={information} additionalInfo={otherinfo} />
                    </div> */}
        </div>
        <div className={style.shop__top__right}>
          <article className={style.heading}>
            <h3>{data.name}</h3>
            <p>{CurrencyConverter(data.price)}</p>
          </article>
          <article className={style.quantity}>
            <Flex
              onClick={handleRemoveQuantity}
              placeContent={"center"}
              placeItems={"center"}
              cursor={"pointer"}
              height={"100%"}
              width={"40%"}
            >
              <svg
                width="11"
                height="2"
                viewBox="0 0 11 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2412 1L1 1"
                  stroke="#3C4242"
                  stroke-width="1.03964"
                  stroke-linecap="round"
                />
              </svg>
            </Flex>
            <span>{quantity}</span>
            <Flex
              cursor={"pointer"}
              onClick={handleAddQuantity}
              placeContent={"center"}
              placeItems={"center"}
              height={"100%"}
              width={"40%"}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.86181 1.37891V10.6201M10.4824 5.99951L1.24121 5.99951"
                  stroke="#3C4242"
                  stroke-width="1.03964"
                  stroke-linecap="round"
                />
              </svg>
            </Flex>
          </article>

          <article className={style.action}>
            <Button
              isDisabled={data?.available_quantity === 0}
              type="button"
              className={style.add__to__cart}
              onClick={handleAddToCart}
              _hover={{
                background: "var(--primary)",
                color: "var(--white)",
                transition: "all 0.3s ease-in-out",
                opacity: "0.8",
              }}
            >
              Add to cart
            </Button>
            <Button
              isDisabled={data?.available_quantity === 0}
              className={style.link__to__live}
              onClick={handleProceed}
            >
              Buy Now
            </Button>
          </article>
          <div className="md:hidden">
            <button className={style.top_btn} type="button">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 11.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H12"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.48 2.81945L19.76 3.38945C19.9 3.66945 20.25 3.92945 20.56 3.98945L20.94 4.04945C22.08 4.23945 22.35 5.07945 21.53 5.90945L21.18 6.25944C20.95 6.49944 20.82 6.95944 20.89 7.27944L20.94 7.48945C21.25 8.86945 20.52 9.39944 19.32 8.67944L19.06 8.52944C18.75 8.34944 18.25 8.34944 17.94 8.52944L17.68 8.67944C16.47 9.40944 15.74 8.86945 16.06 7.48945L16.1099 7.27944C16.1799 6.95944 16.05 6.49944 15.82 6.25944L15.47 5.90945C14.65 5.07945 14.92 4.23945 16.06 4.04945L16.44 3.98945C16.74 3.93945 17.1 3.66945 17.24 3.38945L17.52 2.81945C18.06 1.72945 18.94 1.72945 19.48 2.81945Z"
                  stroke="#29A073"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className={style.top_btn__text}>
                Instant Code Delivery Via Mail
              </span>
            </button>
          </div>
          <Grid fontSize={".875rem"} color={"#667479"} gap={".5rem"}>
            <Text
              fontWeight={"600"}
              py=".65rem"
              w={"100%"}
              borderBottom={"1px solid #EBEEEF"}
            >
              Voucher Redeem Instructions
            </Text>
            <Grid gap={".2rem"} pr={"1rem"}>
              {paragraphs?.map((item: string, index: number) => (
                <Text key={index}>{item}</Text>
              ))}
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr"}
              borderBottom={"1px solid #EBEEEF"}
              py=".65rem"
              gap={"1rem"}
            >
              <Text fontWeight={"600"} w={"100%"}>
                Other Information
              </Text>
              <Grid gap={".2rem"} pr={"1rem"}>
                {otherInfo?.map((item: string, index: number) => (
                  <Text key={index}>{item}</Text>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* <div className = "hidden md:block"> */}
          {/* <Steps information={information} additionalInfo={otherinfo} /> */}
          {/* </div> */}
        </div>
      </section>
      <section className={style.bottom}>
        <div className={style.bottom__top}>
          <h6>Whats new?</h6>
          <Link to={""}>See more Giftcards</Link>
        </div>
        <ShortList
          data={slicedProduct?.length ? slicedProduct : threeProducts}
          handleClick={handleClick}
          fullData={{}}
        />
      </section>
    </main>
  );
};

export default GiftCardShop;
