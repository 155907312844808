import React from 'react'
import style from './success.module.scss'
import { Link } from 'react-router-dom'

import { useAppDispatch } from 'hooks/redux'
import { setSuccess } from 'features/Services/ServicesSlice'

interface success {
    message: React.ReactNode
    title: string
    buttonText: string
    link?: string
    elect?: string
}
/**
 *
 * @param  props.message : React.ReactNode
 * @param  props.title : string
 * @param props.buttonText : string
 * @param props.link : string (optional)
 * @description  This component is used to display a success message.
 * @example  <Success title="Congratulations" message="Welcome to JaraPay, do not share your transaction PIN with anyone." buttonText="Explore Now" />
 * @returns  {React.ReactNode}
 */




const LoginSuccessful: React.FC<success> = ({
    message,
    title,
    buttonText,
    link = '/',
    elect

}) => {
    const dispatch = useAppDispatch()
    const handleClick = ()=> {
        dispatch(setSuccess(false))
    }
    return (
        <div className='w-[100vw] h-[100vh] flex items-center justify-center'>
            <div className={style.container}>
                <div className={style.image__container}>
                    <img className={style.logo} src={'/success.png'} alt="logo" />
                </div>
                <div className={style.message}>
                    <h1>{title}</h1>
                    <p>{message}</p>
                </div>
                <Link to={link} className='grid place-items-center lg:mb-[1.4rem] mb-[.9rem] '>
                    <button className={`btn-primary  ${style.btn} ${style.button}`}>
                        {buttonText}
                    </button>

                </Link>
                {elect ? <Link onClick={handleClick} className='grid place-items-center ' to="/"> <button className={`btn-primary ${style.button} ${style.btn} ${style.elect}`}>
                    {'Dashboard'}
                </button></Link> : null}



            </div>
        </div>
    )
}

export default LoginSuccessful