import React, { useRef, useState, useEffect } from "react";
import style from "./Token.module.scss";
import PinFormatter from "utils/PinFormatter";

const TokenPage = ({
  tokenNumber,
  title = "Enter PIN",
  myPin,
  concentrate = false,
}) => {
  const [values, setValues] = useState(Array(tokenNumber).fill(""));
  const inputRefs = useRef([]);

  const [dirtyFields, setDirtyFields] = useState(
    Array(tokenNumber).fill(false)
  );
  const [, setToken] = useState("");
  const [, setLoading] = useState(false);

  useEffect(() => {
    for (let i = 0; i < tokenNumber; i++) {
      inputRefs.current[i] = React.createRef();
    }
  }, [tokenNumber]);

  useEffect(() => {
    myPin(values);
  }, [myPin, values]);

  const handleKeyDown = async (event, index) => {
    if (event.code === "KeyV" && event.ctrlKey) {
      try {
        const clipboard = await navigator.clipboard.readText();
        if (clipboard.length >= tokenNumber) {
          const newValues = clipboard.split("").slice(0, tokenNumber);
          setValues(newValues);

          inputRefs.current.forEach((ref, i) => {
            ref.current.value = newValues[i];
            setDirtyFields((prev) => {
              const newDirtyFields = [...prev];
              newDirtyFields[i] = newValues[i].length > 0;
              return newDirtyFields;
            });
          });
        }
      } catch (error) {
        alert("Error: Could not read from clipboard");
      }
    }
    if (event.key === "Enter") {
      if (index === tokenNumber - 1 || values[index + 1].length) {
        submitForm();
      } else {
        inputRefs.current[index + 1].current.focus();
      }
    }
    if (event.key === "Backspace" && index > 0 && values[index].length === 0) {
      inputRefs.current[index - 1].current.focus();
      //remove the last item from pin array
    }
    if (event.key === "Backspace" || event.key === "Delete") {
      setPin((prev) => {
        const newPin = [...prev];
        newPin.splice(index, 1);
        return newPin;
      });
    }

    if (
      event.key !== "Backspace" &&
      index < tokenNumber - 1 &&
      values[index + 1].length === 0
    ) {
      if (index === 0 && values[index].length === 0) {
        return;
      }
      inputRefs.current[index + 1].current.focus();
    }
    if (
      event.key === "Tab" &&
      index < tokenNumber - 1 &&
      values[index + 1].length === 0
    ) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const submitForm = async () => {
    setLoading(true);
    const token = values.join("");
    setToken(token);
  };
  const [pin, setPin] = useState([]);

  //use effect to update the pin and setTimeout

  useEffect(() => {
    const validValues = values.filter((value) => value.length > 0);
    const timer = setTimeout(() => {
      //check for values with value e.g ["w" , "x" ,"", ""]

      if (validValues.length > 0) {
        setPin(validValues.map((item) => "⚫"));
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [values]);

  return (
    <div className={style["token"]}>
      <label className={style["token__label"]}>{title}</label>
      <div className={style["token__container"]}>
        {values?.map((value, index) => (
          <input
            name={`token${value}`}
            key={index}
            ref={inputRefs.current[index]}
            value={pin[index] ? pin[index] : value}
            onChange={(e) => {
              const newDirtyFields = [...dirtyFields];
              newDirtyFields[index] = e.target.value !== "";
              setDirtyFields(newDirtyFields);
              const newValues = [...values];
              let pin = PinFormatter(e.target.value);
              newValues[index] = pin;
              setValues(newValues);
            }}
            onKeyDown={(e) => handleKeyDown(e, index)}
            maxLength={1}
            className={`${style["token__input"]}  ${
              (dirtyFields[index] || index === 0) && concentrate
                ? `${style["active"]} ${style["bg-primary/[0.08]"]}`
                : ""
            }`}
            autoFocus={index === 0 && concentrate}
          />
        ))}
      </div>
    </div>
  );
};

export default TokenPage;

//!! Add this later
// ${
//   dirtyFields[index] || index === 0
//     ? `${style["active"]} ${style["bg-primary/[0.08]"]}`
//     : ""
// }
